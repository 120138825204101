import React from 'react'
import pic from '../assets/phone.png'
import GooglePlayIcon from '../assets/google-play-icon.png'
import AppStoreIcon from '../assets/app-play-store.png'
import arrow from '../assets/arrow.png'
import clientimg from '../assets/clientimg.png'
function HeroSection() {

    function RedirectPricing() {
        window.location.href = '#Prices'
    }

    function RedirectSignup() {
        window.location.replace('/signup')
    }

    return (
        
        <div className='  lg:w-[95%] w-[80%] h-[90%] rounded-t-2xl md:flex-row flex-col rounded-2xl flex items-center justify-between md:px-16 px-8  bg-[#03296b] space-y-10'>
            <div className='md:w-[50%] w-full flex space-y-4 flex-col mt-10 lg:items-start items-center text-white py-6  '>
                
                    <text className='text-center md:text-5xl text-3xl font-bold text-white '>A SUA PROFESSORA
                    </text>
                    <text className='text-center  md:text-5xl text-3xl text-[#EFD167] font-bold whitespace-normal '>DE INGLÊS VIRTUAL</text>
                    <p className=' md:text-5xl text-3xl font3 font-extrabold text-white24/7 mb-2'>24/7</p>
                    
                

                <div className='w-full flex flex-row md:justify-start justify-center items-start md:h-[36vh]'>
                <button className='shadow-lg mt-10 px-12 py-4 rounded-full font-bold text-2xl text-white bg-[#fcc502]' onClick={RedirectSignup}>Inscrever-se</button>
                </div>
                 
                 <div className='flex flex-col'>
                 
                    <p className='text-md font-bold mb-4'>Ou baixe o aplicativo:</p>
                <div className='flex flex-row items-start justify-start mb-8'>
                
                    <a href='#'>
                        <img src={GooglePlayIcon} alt='Google Play' className='mt-auto w-40 h-15 mr-4'></img>
                    </a>
                    <a href='#'>
                        <img src={AppStoreIcon} alt='App Store' className='mt-auto w-40 h-38 ml-4'></img>
                    </a>
                </div>
                </div>


            </div>

            <div className='md:w-[50%] w-full flex justify-center place-self-end '>
            <img src={arrow} alt='Google Play' className='mt-auto w-40 h-15 mb-32 md:block hidden'></img>
                
                <img src={clientimg} className='md:w-5/6 w-full'></img>
                            </div>

        </div>
        
    )
}

export default HeroSection

/* */