import React from 'react'
import { FaRobot } from 'react-icons/fa'
import { BsSoundwave } from 'react-icons/bs'
import axios from 'axios'
import { useState } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
function BotChat(props) {

  const [isPlaying, setIsPlaying] = useState(false)
  const [isPlayingPT, setIsPlayingPT] = useState(false)
  const [isPlayingEN, setIsPlayingEN] = useState(false)


  const [audio, setAudio] = useState(null)


  const playMp3 = async (mp3, lang) => {

    if (isPlaying) {
      audio.pause()
      setIsPlaying(false)
      return
    }

    var audioo = new Audio(`data:audio/mpeg;base64,${mp3}`)
    setAudio(audioo)
    setIsPlaying(true)
    var playPromise = audioo.play()

    if (playPromise !== undefined) {
      playPromise.then(_ => {
        // Automatic playback started!
        // Show playing UI.

      })
        .catch(error => {
          // Auto-play was prevented
          // Show paused UI.
        });
      //window.location.assign(audioDataUrl);   
      // playBlobAudio(blob);
      //return res.data;

    }

    audioo.addEventListener('ended', () => {

      setIsPlaying(false)

      if (lang === 'pt-BR') {
        setIsPlayingPT(false)
      }
      else {
        setIsPlayingEN(false)
      }

    })
  }

  async function tts(text, lang) {

    if (lang === 'pt-BR') {

      const res = await axios.post(
        `https://texttospeech.googleapis.com/v1/text:synthesize?key=${process.env.REACT_APP_GAPI}`,
        {
          "input": {
            "text": text
          },
          "voice": {
            "languageCode": "pt-BR",
            "name": "pt-BR-Neural2-A",
          },
          "audioConfig": {
            "audioEncoding": "MP3"
          }

        },
        {
          headers: {
            //'Authorization': `Bearer ${process.env.REACT_APP_GAPI}`,
            'Accept': 'application/json',
          },
        }
      );
      playMp3(res.data.audioContent, lang)
    }
    else {
      const res = await axios.post(
        `https://texttospeech.googleapis.com/v1/text:synthesize?key=${process.env.REACT_APP_GAPI}`,
        {
          "input": {
            "text": text
          },
          "voice": {
            "languageCode": "en-US",
            "name": "en-US-Studio-O",
          },
          "audioConfig": {
            "audioEncoding": "MP3"
          }

        },
        {
          headers: {
            //'Authorization': `Bearer ${process.env.REACT_APP_GAPI}`,
            'Accept': 'application/json',
          },
        }
      );
      playMp3(res.data.audioContent, lang)
    }





  };
  return (
    <div class='w-full justify-stretch items-stretch flex md:flex-row flex-col pt-5 pb-8 px-5 font-font2 space-x-5 rounded-lg bg-[#03296b]/5'>

      <div className="flex flex-col">

        <div class='md:p-2 p-1 h-fit md:border-[2px] border-[1px] md:max-w-[2vw] max-w-[8vw] rounded-full bg-[#fcc502]'>
          <FaRobot color='white' size='1.2rem' />
        </div>

        <div />

        <ReactMarkdown className="min-w-[40vw] space-y-2" id="BotTextHtml">{props.content}</ReactMarkdown>
        {/* <div id="BotTextHtml" className="min-w-[40vw]" dangerouslySetInnerHTML={{ __html: props.content }}></div> */}

      </div>



      <div className="flex md:flex-col items-end justify-end ">
        <button
          onClick={() => {
            if (isPlayingPT) {
              setIsPlayingPT(false)
            }
            else {
              setIsPlayingPT(true)
            }


            var text = `${props.content}`
            var text2 = text.replace(/<[^>]*>/g, '')
            console.log(text2)
            tts(text2, 'pt-BR')
          }}
          className={`py-2 px-2 flex items-center md:hover:bg-[#fcc502] rounded-lg ${isPlayingPT ? 'bg-[#fcc502]' : ''}`}>


          <div className="flex space-x-1">
            <div className='pt-1'>PT</div>
            <BsSoundwave size='2rem' />
          </div>
        </button>

        <button
          onClick={() => {

            if (isPlayingEN) {
              setIsPlayingEN(false)
            }
            else {
              setIsPlayingEN(true)
            }



            var text = `${props.content}`
            var text2 = text.replace(/<[^>]*>/g, '')
            console.log(text2)
            tts(text2, 'en-US')
          }}
          className={`py-2 px-2 flex items-center md:hover:bg-[#fcc502] rounded-lg ${isPlayingEN ? 'bg-[#fcc502]' : ''}`}>

          <div className="flex space-x-1">
            <div className='pt-1'>EN</div>
            <BsSoundwave size='2rem' />
          </div>
        </button>
      </div>
    </div>
  )
}

export default BotChat