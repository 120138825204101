import React, { useState } from 'react'
import { AiOutlineArrowDown } from 'react-icons/ai'
import { US, ES, FR } from 'country-flag-icons/react/3x2'

function Sidebar(props) {

    const [LangCheck, setLangCheck] = useState(false)

    function OpenLanguages() {
        setLangCheck(!LangCheck)
    }

    return (
        <div class='md:hidden w-full flex flex-col py-5 px-8 text-black/60 font-font2 text-lg bg-white space-y-14'>
            <div class='w-full space-y-4 flex flex-col items-start'>

                {
                    props.check != 1 && props.check != 2 ?
                        <>   <button onClick={props.PriceNavigation} class='hover:text-black'>Preço</button>
                            <button class='hover:text-black' onClick={() => window.location.href = '#About'}>Sobre</button>
                            <button class='hover:text-black' onClick={() => window.location.href = '#Contact'}>Contatos</button>
                            <button class='hover:text-black' onClick={() => window.location.href = '#'}>Download</button>

                        </>

                        :
                        <></>
                }

            </div>

            <div class='w-full flex flex-col space-y-4'>
                {
                    props.check != 2 ?
                        <button onClick={props.RedirectLogin} class='py-2 bg-[#fcc502] text-white rounded-lg'>Sign in</button>
                        :
                        <></>
                }
                {
                    props.check != 1 ?
                        <button onClick={props.RedirectSignup} class='py-2 rounded-lg border-[2px] border-[#fcc502] text-[#fcc502]'>Sign up</button>
                        :
                        <></>
                }
            </div>
        </div>
    )
}

export default Sidebar