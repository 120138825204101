import React from 'react'
import { BsCheckLg } from 'react-icons/bs'

function StandardPlan(props) {

    return (
        <div className='w-[40vh] h-[46vh] bg-[#102048] flex flex-col items-center justify-center rounded-2xl shadow-lg border border-gray-200'>
            <div className='bg-white py-1.5 px-5 rounded-md '>
                <p className='text-[#102048] font-bold '>Salve o negócio</p>
            </div>

            <div className='flex flex-row mt-4 mb-4'>
                <p className='font-bold text-3xl text-white'>€90 </p>
                <p className='mt-1 ml-2 font-light text-xl text-white'>/year</p>
            </div>

            <div className='flex flex-row mt-4 mb-4 line-through'>
                <p className='font-bold text-xl text-white'>€120 </p>
                <p className=' ml-2 font-light text-lg text-white'>/year</p>
            </div>

            <p className='text-[#FDC500] font-semibold text-3xl mb-2'>Economize € 30</p>




            <button className=' py-4 px-3 mt-4 bg-white text-black border rounded-xl font-bold hover:font-bold hover:text-xl transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 '
                onClick={props.button}
            >
                Pegue o negócio</button>

        </div>



    )
}

export default StandardPlan

/*
onClick={props.button}*/