import { createClient } from "@supabase/supabase-js";
const supabase = createClient('https://jnhpjjeqcxucliwgosit.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpuaHBqamVxY3h1Y2xpd2dvc2l0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODg4NDg4NTMsImV4cCI6MjAwNDQyNDg1M30.IHL6Z3GTYuX4dWnW7agYS-eDZ_7u0H7roryUeub4DDY')
const checkIfLogin = () => {
    const session = JSON.parse(
        localStorage.getItem("sb-jnhpjjeqcxucliwgosit-auth-token")
    );

    if (session) {
        // YOu are logged in
        const accessToken = session.access_token;
        sessionStorage.setItem("key", accessToken);
        return accessToken;
    } else {
        // Error, maybe wonrg password
        return false;
    }
};

export { supabase, checkIfLogin };