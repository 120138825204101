import React from 'react'
import { Simulate } from 'react-dom/test-utils'
import SinglePrice from './SinglePrice'
import PremiumPlan from './PremiumPlan'
import StandardPlan from './StandardPlan'
import arrow2 from '../assets/arrow2.png'


function Pricing() {
  function RedirectSignup() {
    window.location.replace('/signup')
  }



  return (
    <div id='Prices' className='h-full mt-8'>

      <div className='flex flex-row items-center justify-center w-full'>
        <p className='text-3xl font-bold text-[#102048]'>
          Preços
        </p>
        <img src={arrow2} alt='arrow2' className='md:ml-2 mb-4 md:w-12 w-10 h-15' />
      </div>

      <div className='grow flex items-center justify-center w-full'>

        <div className='lg:w-[70%] w-full flex lg:flex-row flex-col justify-between lg:items-start items-center pb-8 md:mt-10 mt-4 '>


          <SinglePrice />
          <PremiumPlan button={RedirectSignup} />
          <StandardPlan button={RedirectSignup} />
        </div>

      </div>

    </div>

  )
}

export default Pricing

/*sm:px-14 px-8 pb-4 */