import React from 'react'
import { BsVectorPen, BsBookHalf } from 'react-icons/bs'

function Sidebar2(props) {

    function RedirectLogin() {
        window.location.replace('/login')
    }

    return (
        <div class='w-[50%] h-full fixed bg-[#03296b]/5 backdrop-blur-md flex md:hidden flex-col pb-10 pt-20 lg:px-4 md:px-4 items-start justify-between'>

            <div class='w-full flex flex-col space-y-1'>

                <button
                    onClick={() => props.setIsLesson(true)}
                    class={`flex transition-all items-center font-font2 space-x-5 py-3 px-3 rounded-full hover:bg-[#f1ce4d] ${props.isLesson ? "bg-[#fcc502]" : ""}`}>
                    <BsVectorPen size='1.5rem' color='#03296b' />
                    <text class='text-xl'>Aprender</text>
                </button>

                <button
                    onClick={() => props.setIsLesson(false)}
                    class={`flex transition-all items-center font-font2 space-x-5 py-3 px-3 rounded-full hover:bg-[#f1ce4d] ${!props.isLesson ? "bg-[#fcc502]" : ""}`}>
                    <BsBookHalf size='1.5rem' color='#03296b' />
                    <text class='text-xl'>Lição</text>
                </button>

            </div>

            <div class='flex space-x-2 ml-1'>
                <button
                    onClick={props.logOut}
                    class='p-3 bg-[#03296b] font-font1 text text-white rounded-lg ' >Log out</button>
                <button
                    onClick={props.payment}
                    class='p-3 bg-[#03296b]  font-font1 text text-white rounded-lg ' >Cobrança</button>
            </div>
        </div>
    )
}

export default Sidebar2