import React from 'react'
import Navbar from './Navbar'
import { useState, useEffect } from 'react'
import { supabase, checkIfLogin } from '../supa'
import Swal from "sweetalert2";
import axios from 'axios';
import Message from '../assets/Message.png'
import Lock from '../assets/Lock.png'


function Login() {
    const [user, setUser] = useState(null)
    const [password, setPassword] = useState(null)
    const [loading, setLoading] = useState(false)


    function RedirectSignup() {
        window.location.replace('/signup')
    }




    async function RedirectDashboard() {
        setLoading(true)
        const { data, error } = await supabase.auth.signInWithPassword({
            email: user,
            password: password,
        })

        if (error) {
            Swal.fire({
                title: error.message,
                icon: "error",
                confirmButtonText: "ok",
            });
            setLoading(false)
            return
        }

        if (checkIfLogin()) {
            const jwt = `Bearer ${checkIfLogin()}`

            // await axios
            //     .get(
            //         `${process.env.REACT_APP_BACKEND}/stripe/getDashboard`,
            //         {
            //             headers: {
            //                 Authorization: jwt,
            //             },
            //         }
            //     )
            window.location.replace('/dashboard')
        }
        else {
            Swal.fire({
                title: "User not found!",
                icon: "error",
                confirmButtonText: "ok",
            });
            setLoading(false)
            return
        }
        setLoading(false)
    }

    return (
        <>

            {loading && (<div className="flex items-center justify-center w-full min-h-screen transition-all ">
                <div className="relative">
                    <div className="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
                    <div className="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin">
                    </div>
                </div>
            </div>)}

            <Navbar check={2} />

            <div className='w-full h-screen flex flex-col justify-center items-center'>

                <div className='w-full flex flex-col md:items-center items-start md:ml-0 ml-12'>
                    <p className='md:text-6xl text-4xl font-extrabold'>Welcome Back</p>
                    <p className='md:text-xl text-lg text-[#7B7B7B] mt-4'>Login to your account</p>
                </div>

                <div className='mb-2 rounded-r-2xl w-[90%] lg:w-[40%]  border border-gray-200 shadow-lg flex flex-row items-center md:justify-between  mt-10 custom-border'>
                    <div className='flex items-center grow'>
                        <img src={Message} alt='email' className='w-14 h-14 mr-2' />
                        <input type='text' onChange={(e) => setUser(e.target.value)} className='grow bg-transparent focus:outline-none' placeholder='Email' />
                    </div>
                </div>

                <div className='mb-2 rounded-r-2xl w-[90%] lg:w-[40%]  border border-gray-200 shadow-lg flex flex-row items-center md:justify-between  mt-3 custom-border '>
                    <div className='flex items-center grow'>
                        <img src={Lock} alt='password' className='w-14 h-14 mr-2' />
                        <input type='password' onChange={(e) => setPassword(e.target.value)} className='grow bg-transparent focus:outline-none' placeholder='Password' />
                    </div>
                </div>

                <div className='w-[90%] lg:w-[40%] flex items-end justify-end mr-4 mt-2'>
                    <p className='text-[#102048] text-lg'>Forgot Password</p>

                </div>


                <button className='py-4 lg:w-[35%] w-[90%] bg-[#102048] text-lg text-white mt-20 mb-8 rounded-full' onClick={RedirectDashboard}>Login </button>

                <div className='flex justify-center items-center'>
                    <p className='text-lg'>Don't have an account? </p>
                    <button class='text-[#14227D] ml-2  text-lg font-semibold' onClick={RedirectSignup}>Sign up</button>
                </div>










            </div>












        </>







    )
}

export default Login


/*<div class='w-full flex flex-col'>
                        <div class='w-full flex flex-col'>
                            <text class='text-black/70'>Email</text>
                            <input onChange={(e) => setUser(e.target.value)} class='w-full pt-3 border-b-[2px] outline-none pb-1' placeholder='abc@mail.com'></input>
                        </div>
                        <div class='w-full flex flex-col'>
                            <text class='text-black/70'>Password</text>
                            <input onChange={(e) => setPassword(e.target.value)} class='w-full pt-3 border-b-[2px] outline-none pb-1' placeholder='........' type='password'></input>
                        </div>
                    </div>

                    <div class='w-full flex flex-col'>
                        <button class='w-full py-3 rounded-xl font-font2 text-lg bg-[#03296b] text-white' onClick={RedirectDashboard}>Entrar</button>
                        <div class='flex items-center '>
                            <hr class='w-1/2 border-[1px]' />
                            <text class='text-black/50'>ou</text>
                            <hr class='w-1/2 border-[1px]' />
                        </div>



                         <div class='flex w-full justify-center'>
                            <text>Don't have an account? </text>
                            <button class='text-[#03296b] underline' onClick={RedirectSignup}>Signup</button>
                        </div>





                         {loading && (<div className="flex items-center justify-center w-full min-h-screen transition-all ">
                <div className="relative">
                    <div className="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
                    <div className="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin">
                    </div>
                </div>
            </div>)}
            <Navbar check={2} />
           
                     */