import React from 'react'
import arrow2 from '../assets/arrow2.png';
import img1 from '../assets/img1.png';
import img2 from '../assets/img2.png';
import img3 from '../assets/img3.png';
import rectangle from '../assets/rectangle.png';
import ending from '../assets/ending.png';

const Section2 = () => {

  return (
    <div className='mt-10 w-full flex flex-col items-center justify-center'>

      <div className='flex flex-row'>
        <p id="About" className='md:text-3xl text-lg font-bold text-[#102048]'>
          Maneira mais fácil de aprender idiomas
        </p>
        <img src={arrow2} alt='arrow2' className='md:ml-2 mb-4 md:w-12 w-10 h-15' />
      </div>

      <div className="flex lg:flex-row flex-col justify-center mt-6">
        <div className="w-84 h-68 border border-gray-200 shadow-md m-4 rounded-lg ">
          <img src={img1} alt='arrow2' className='ml-2 mt-2 md:w-14 w-10 h-15' />
          <div className='w-64 ml-2 mr-2 mt-8 mb-0'>
            <p className='text-[#102048] font-bold text-md '>Ensino Personalizado</p>
            <p className='mt-2 text-[#1A1A1A] mb-2'>A inteligência artificial como Kassoma pode adaptar o conteúdo e o ritmo de aprendizagem de acordo com as necessidades individuais de cada aluno.</p>
          </div>

        </div>

        <div className="w-84 h-68 border border-gray-200 shadow-md m-4 rounded-lg ">
          <img src={img2} alt='arrow2' className='ml-2 mt-2 md:w-14 w-10 h-15' />
          <div className='w-64 ml-2 mr-2 mt-8 mb-0'>
            <p className='text-[#102048] font-bold text-md '>Maneira Eficiente de Aprender</p>
            <p className='mt-2 text-[#1A1A1A] mb-2'>
              O AI English Tutor adapta seus cursos e conteúdos de acordo com seus requisitos específicos e nível atual de habilidade.
            </p>
          </div>  </div>

        <div className="w-84 h-68 border border-gray-200 shadow-md m-4 rounded-lg ">
          <img src={img3} alt='arrow2' className='ml-2 mt-2 md:w-14 w-10 h-15' />
          <div className='w-64 ml-2 mr-2 mt-8 mb-0'>
            <p className='text-[#102048] font-bold text-md '>Feedback em tempo real</p>
            <p className='mt-2 text-[#1A1A1A] mb-2'>Melhore suas habilidades linguísticas aprendendo com feedback instantâneo sobre sua pronúncia, gramática e uso de vocabulário.</p>
          </div>

        </div>


      </div>






    </div>


  )
}

export default Section2


/* */
