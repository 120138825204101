import React, { useState } from 'react'
import logo from '../assets/logo.png'
import { AiOutlineArrowDown } from 'react-icons/ai'
import { BiMenu } from 'react-icons/bi'
import Sidebar from './Sidebar'
import { US, ES, FR } from 'country-flag-icons/react/3x2'

function Navbar(props) {

    const [MenuCheck, setMenuCheck] = useState(false)

    function OpenMenu() {
        setMenuCheck(!MenuCheck)
    }

    function PriceNavigation() {
        window.location.href = '#Prices'
    }

    function RedirectLogin() {
        window.location.replace('/login')
    }
    function RedirectSignup() {
        window.location.replace('/signup')
    }
    function RedirectLanding() {
        window.location.replace('/')
    }

    return (
        <div class='w-full fixed flex flex-col z-20'>
            <div class='w-full bg-white py-3 pl-8 pr-8 flex justify-between items-center z-[999]'>
                <div class='w-fit flex items-center'>
                    <img src={logo} class='lg:w-10 w-7 mr-4' />
                    <button class='lg:text-4xl text-2xl font-font1 font-bold' onClick={() => { window.location.replace("/") }}>Kassoma AI</button>
                </div>
                <div class='md:flex hidden flex-row  lg:text-xl text-lg font-font2 lg:space-x-14 space-x-8 items-center'>


                    {
                        props.check != 1 && props.check != 2 ?
                            <>
                                <button class='hover:text-[#fcc502] ' onClick={PriceNavigation}>Preços</button>
                                <button class='hover:text-[#fcc502] ' onClick={() => window.location.href = '#About'}>Sobre</button>
                                <button class='hover:text-[#fcc502] ' onClick={() => window.location.href = '#Contact'}>Contatos</button>
                                <button class='hover:text-[#fcc502] ' onClick={() => window.location.href = '#'}>Download</button>

                            </>

                            :
                            <></>
                    }

                </div>
                <div class='md:flex hidden flex-row text-white text-xl font-font2 space-x-5 items-center'>
                    <button class='text-lg text-black lg:ml-0 ml-4' onClick={RedirectLogin}>Enterar</button>

                    {
                        props.check != 1 ?
                            <button class='h-fit lg:py-2 py-1 lg:px-8 px-6 bg-[#fcc502] rounded-full' onClick={RedirectSignup}>Inscrever-se</button>
                            :
                            <></>
                    }
                </div>
                <div class='md:hidden flex'>
                    <BiMenu size='2rem' class='hover:bg-gray-400/50 rounded-lg' onClick={OpenMenu} />
                </div>
            </div>
            {
                MenuCheck == true ?
                    <Sidebar check={props.check} RedirectLogin={RedirectLogin} RedirectLanding={RedirectLanding} PriceNavigation={PriceNavigation} RedirectSignup={RedirectSignup} />
                    :
                    <></>
            }
        </div>
    )
}

export default Navbar


/*<div class='flex flex-col'>
                        <button class='py-1 flex items-center hover:text-[#fcc502]' onMouseEnter={() => setDropDown(true)} onMouseLeave={() => setDropDown(false)} >
                            Seu idioma
                            <AiOutlineArrowDown class='ml-2' />
                        </button>
                        {
                            DropDown == true ?
                                <div class='flex flex-col  mt-8 space-y-3 py-3 pl-6 pr-20 border-[2px] rounded-lg absolute bg-[#03292b] text-white' onMouseEnter={() => setDropDown(true)} onMouseLeave={() => setDropDown(false)}>
                                    <button class='flex items-center text-start hover:text-[#fcc502]'>
                                        <US class='w-6 mr-3' />
                                        <text>English</text>
                                    </button>
                                    <button class='flex items-center text-start hover:text-[#fcc502]'>
                                        <ES class='w-6 mr-3' />
                                        <text>Spanish</text>
                                    </button>
                                    <button class='flex items-center text-start hover:text-[#fcc502]'>
                                        <FR class='w-6 mr-3' />
                                        <text>French</text>
                                    </button>
                                </div>
                                :
                                <></>
                        }
                    </div> */