import React from 'react';
import ending from '../assets/ending.png';
import bluepattern from '../assets/bluepattern.png';
import email from '../assets/email.png';
import iconn from '../assets/iconn.png';

const Section4 = () => {
  return (
    <div id="Contact" className='w-full flex flex-col items-center justify-center h-full'>

      <div className=' w-full md:w-[90%] bg-[#102048] flex flex-col items-center justify-center border rounded-xl  py-14'>

        <h2 className='md:text-4xl text-xl font-semibold text-white md:mt-4 mt-0 mb-4'>Assine Newsletter e receba atualizações</h2>

        <div className='mb-2 rounded-full w-[90%] lg:w-[40%] md:py-3 py-1  border border-black bg-gray-100 flex flex-row items-center md:justify-between lg:p-2 p-1 lg:mt-8 mt-2'>
          <div className='flex items-center mb-2 md:mb-0'>
            <img src={email} alt='email' className='w-6 h-6 mr-2 text-gray-500' />
            <input type='text' className='bg-transparent focus:outline-none' placeholder='Your email' />
          </div>

          <button className='text-white text-md bg-[#FDC500] px-6 md:py-3 py-1  rounded-full md:ml-2'>
            <div className='flex items-center'>
              <img src={iconn} alt='iconn' className='w-6  h-6 mr-2 text-gray-500' />
              <p className=''>Se inscrever</p>
            </div>
          </button>
        </div>

      </div>

      <img src={ending} alt='rect' className='md:w-[70%] w-[80%] h-auto mt-8' />

    </div>
  );
}

export default Section4;

/*absolute  */
