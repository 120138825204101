import React from 'react'
import arrow2 from '../assets/arrow2.png';
import fullphone from '../assets/fullphone.png';
import tick3 from '../assets/tick3.png';
import crazy from '../assets/crazy.png';
import newphone from '../assets/newphone.png';


const Section3 = () => {


  const tick = [
    { image: tick3, text: "Melhora a fluência de falantes não nativos em inglês falado" },
    { image: tick3, text: "Sugerir Tópico para sobre sobre" },
    { image: tick3, text: "Você pode conversar com o tutor personalizado a qualquer hora e em qualquer lugar" },
    { image: tick3, text: "Dê feedback em tempo real" },
    { image: tick3, text: "Correção na pronúncia pelo áudio" },
  ];

  const tick2 = [
    { image: tick3, text: "Continue praticando questionários diários" },
    { image: tick3, text: "Obtenha uma pontuação e verifique seu nível de conhecimento em inglês" },
  ];

  const plan = [
    { image: tick3, text: "Melhora a fluência de falantes não nativos em inglês falado" },
    { image: tick3, text: "Sugerir Tópico para sobre sobre" },
  ];

  const tick4 = [
    { image: tick3, text: "Grave áudio ou envie um arquivo de texto, e a IA ira` analisa`-lo rapidamente" },
    { image: tick3, text: "Você recebera` feedback detalhado e em tempo real" },
    { image: tick3, text: "Obtenha a versão corrigida" },
    { image: tick3, text: "Verifique sua pontuação e nivel de inglês" },
    
  ];

  

  return (
    <div className='mt-2 w-full flex flex-col items-center justify-center min-h-screen'>


      <div className='flex flex-row items-center justify-center'>
        <p className='md:text-3xl text-2xl font-bold text-[#102048]'>
          Nossos melhores recursos
        </p>
        <img src={arrow2} alt='arrow2' className='md:ml-2 mb-4 md:w-12 w-10 h-15' />
      </div>

      <div className='lg:w-[65%] w-[85%] h-[70%] rounded-2xl mt-8 bg-[#FBF2D0]'>
        <div className='flex flex-row'>

          <div className='lg:w-[45%] h-full lg:flex items-center justify-center hidden'>
            <img src={fullphone} alt='fullphone' className='md:ml-8 mb-4 md:w-full h-15' />
          </div>

          <div className='w-full flex flex-col items-center justify-center'>
            <p className='text-[#102048] lg:text-3xl text-2xl font-bold mb-4'>Kassoma Chat AI</p>


            <div className='flex flex-col items-start'>
              {tick.map((entry, index) => (

                <div className='flex flex-row items-center justify-center mb-3' key={index}>
                  <img src={entry.image} alt='tick' className='ml-2 w-6 h-15' />
                  <p className='ml-2 text-lg text-[#000000]'>{entry.text}</p>
                </div>

              ))}
            </div>



          </div>


        </div>

      </div>


      <div className='mt-12 mb-8 flex lg:flex-row flex-col items-center justify-center '>


        <div className=' lg:w-[40%] w-full flex flex-col lg:items-start items-center justify-center'>
          <p className='text-[#102048] lg:text-3xl text-2xl font-bold mb-6'>Faça testes diários</p>

          {tick2.map((entry, index) => (

            <div className='flex flex-row items-center justify-center mb-4 mt-2' key={index}>
              <img src={entry.image} alt='tick' className='ml-2 w-6 h-15' />
              <p className='ml-2 text-lg text-[#000000]'>{entry.text}</p>
            </div>

          ))}


        </div>

        <div className='lg:w-[50%] w-[45%] h-full flex items-center justify-center lg:mt-0 mt-6'>
          <img src={crazy} alt='fullphone' className=' mb-4 md:w-full h-15' />
        </div>






      </div>

      <div className='lg:w-[65%] w-[80%] h-[70%] rounded-2xl mt-8 bg-[#FBF2D0]'>
        <div className='flex flex-row'>

          <div className='lg:w-[60%] h-full lg:flex items-center justify-center hidden ml-2'>
            <img src={newphone} alt='fullphone' className='md:ml-8 md:w-full h-15' />
          </div>

          <div className='w-full flex flex-col items-center justify-center ml-2'>
            <p className='text-[#102048] lg:text-3xl text-2xl font-bold mb-4'>Revise sua fala e excrita </p>


            <div className='flex flex-col items-start lg:ml-4 '>
              {tick4.map((entry, index) => (

                <div className='flex flex-row items-center justify-center mb-3' key={index}>
                  <img src={entry.image} alt='tick' className='ml-2 w-6 h-15' />
                  <p className='ml-2 text-lg text-[#000000]'>{entry.text}</p>
                </div>

              ))}
            </div>



          </div>


        </div>

      </div>









    </div>



  )
}

export default Section3

/*



  */
