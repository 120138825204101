import React from 'react'

function BackgroundSVG(props) {
  return (
    <div class={props.check==1?'absolute w-full h-full flex justify-end items-start':'absolute w-full flex h-full items-end -z-50'}>
        {
          props.check==1?
          <div class='w-[100%]'>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill="#FDD36A" fill-opacity="1" d="M0,256L1440,32L1440,0L0,0Z"></path>
</svg> </div>
          :
          <div class='w-full '>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3f4f5" fill-opacity="1" d="M0,128L26.7,144C53.3,160,107,192,160,176C213.3,160,267,96,320,64C373.3,32,427,32,480,48C533.3,64,587,96,640,117.3C693.3,139,747,149,800,170.7C853.3,192,907,224,960,240C1013.3,256,1067,256,1120,229.3C1173.3,203,1227,149,1280,144C1333.3,139,1387,181,1413,202.7L1440,224L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"></path></svg>
          </div>
        }
    </div>
  )
}

export default BackgroundSVG