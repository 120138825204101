import React from 'react'
import {FaUser} from 'react-icons/fa'

function UserChat(props) {
  return (
    <div class='w-full flex pt-5 pb-8 px-5 font-font2 space-x-5'>
        <div class='p-2 h-fit border-[2px] rounded-full bg-[#03296b]'>
            <FaUser color='white' size='1.2rem'/>
        </div>
        <text>{props.content}</text>
    </div>
  )
}

export default UserChat