import React from 'react'
import logoo from '../assets/logoo.png'
import { BiLogoInstagram } from 'react-icons/bi'
import { FaFacebookSquare } from 'react-icons/fa'
import { CiTwitter } from 'react-icons/ci'
import { Link } from 'react-router-dom'



const Footter = () => {


  function PriceNavigation() {
    window.location.href = '#Prices'
  }



  const Login = () => {

    const instagramLoginURL = 'https://www.instagram.com/accounts/login/';
    window.location.href = instagramLoginURL;
  };

  const LoginFb = () => {

    const fbLoginURL = 'https://www.facebook.com/login/';
    window.location.href = fbLoginURL;

  };

  const loginX = () => {
    const twitterLoginURL = 'https://twitter.com/login';
    window.location.href = twitterLoginURL;
  };


  return (
    <>
      <div className='flex lg:flex-row flex-col justify-between items-center w-full'>
        <img src={logoo} class='lg:w-18 w-15 lg:ml-4 ml-0' />

        <div className='flex flex-row  items-center justify-center mr-6'>
          <div className='bg-[#102048] lg:px-2 px-4 lg:py-2 py-4 rounded-xl ml-1'>
            <BiLogoInstagram className='text-white' onClick={Login} />
          </div>
          <div className='bg-[#102048] lg:px-2 px-4 lg:py-2 py-4 rounded-xl ml-1'>
            <FaFacebookSquare className='text-white' onClick={LoginFb} />
          </div>
          <div className='bg-[#102048] lg:px-2 px-4 lg:py-2 py-4 rounded-xl ml-1'>
            <CiTwitter className='text-white' onClick={loginX} />
          </div>




        </div>



      </div>

      <div className='w-full flex items-center justify-center'>
        <div className='flex flex-col items-center justify-center w-full'>
          <div className="w-[90%] h-1 bg-gray-300 my-5" />

          <div className='w-[90%] flex  lg:flex-row flex-col items-start justify-between '>
            < Link to='/' className=' text-[#353535] font-semibold'>Sobre</Link>
            <Link to='/' className=' text-[#353535] font-semibold' >Feição </Link>
            <a href='#Prices' className=' text-[#353535] font-semibold' onClick={PriceNavigation}>Preços</a>
            <Link to='/' className='text-[#353535] font-semibold'> Boletim de Notícias</Link>
            <Link to='/' className=' text-[#353535] font-semibold'>Download</Link>
            <Link to='/login' className=' text-[#353535] font-semibold'> Entrar</Link>
            <Link to='/signup' className=' text-[#353535] font-semibold'>Inscrever-se</Link>



          </div>


          <p className='mt-10 mb-6'>© power 2023 - All Rights Reserved</p>
        </div>
      </div>

    </>
  )
}

export default Footter
