import React from 'react'
import tick2 from '../assets/tick2.png'

function PremiumPlan(props) {

    return (
        <div className='w-[40vh] h-[46vh]  bg-[#FDC500] flex flex-col items-center justify-center rounded-2xl shadow-lg border border-gray-200'>
            <div className='bg-[#102048] py-2 px-5 rounded-md '>
             <p className='text-white font-bold '>Pró</p>
            </div>
            <div className='flex flex-row mt-4 mb-4'>
            <p className='font-bold text-4xl'>€10 </p>
            <p className='mt-1 ml-2 font-light text-2xl'>/mo</p>
            </div>


            <div className='flex flex-col justify-start '>
            
                    <div className='flex flex-row mb-2'>
                    <img src={tick2} alt='tick' className='ml-1 w-5 h-5 mr-2 '/>
                    <p className='font-md text-semibold'>Bate-papo ilimitado</p>
                    

                </div>

                <div className='flex flex-row mb-2'>
                    <img src={tick2} alt='tick' className='ml-1 w-5 h-5 mr-2 '/>
                    <p className='font-md text-semibold'>Revisar Atribuição Ilimitada</p>

                </div>

                <div className='flex flex-row mb-2'>
                    <img src={tick2} alt='tick' className='ml-1 w-5 h-5 mr-2 '/>
                    <p className='font-md text-semibold'>Revisão aprofundada de tudo</p>

                </div>

                <div className='flex flex-row mb-2'>
                    <img src={tick2} alt='tick' className='ml-1 w-5 h-5 mr-2 '/>
                    <p className='font-md text-semibold'>Questionário Interessante Diário</p>

                </div>
     
               <button className=' py-4 mt-4 bg-white text-black border rounded-xl font-bold hover:font-bold hover:text-xl transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110'
               onClick={props.button}
               
               >
                Comprar</button>

            </div>



        </div>
    )
}

export default PremiumPlan