import React from 'react'
import Navbar from './Navbar'
import { useState, useEffect } from 'react'
import { supabase } from '../supa'
import Swal from "sweetalert2";
import Message from '../assets/Message.png'
import Lock from '../assets/Lock.png'
import userr from '../assets/userr.png'

function Signup() {

    const [user, setUser] = useState(null)
    const [password, setPassword] = useState(null)
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState(null)




    useEffect(() => {
        console.log()
    }, [])
    async function RedirectLogin() {
        setLoading(true)
        const { data, error } = await supabase.auth.signUp({
            email: user,
            password: password
        })

        if (error) {
            Swal.fire({
                title: error.message,
                icon: "error",
                confirmButtonText: "ok",
            });
            setLoading(false)
            return
        }

        if (data) {
            if (data?.user?.identities?.length === 0) {
                Swal.fire({
                    title: "User already exists!",
                    icon: "error",
                    confirmButtonText: "ok",
                });
                return;
            }
            else {
                await Swal.fire({
                    title: "User created, Please check your email to verify your account!",
                    icon: "success",
                    confirmButtonText: "ok",
                });

                window.location.replace('/login')
                return;
            }
        }
        setLoading(false)
        return
    }

    return (
        <>
            {loading && (<div className="flex items-center justify-center w-full min-h-screen transition-all ">
                <div className="relative">
                    <div className="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
                    <div className="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin">
                    </div>
                </div>
            </div>)}

            <Navbar check={1} />
            <div className='w-full h-screen flex flex-col justify-center items-center'>

                <div className='w-full flex flex-col md:items-center items-start md:ml-0 ml-12'>
                    <p className='md:text-6xl text-4xl font-extrabold'>Register</p>
                    <p className='md:text-xl text-lg text-[#7B7B7B] mt-4'>Create a new account</p>
                </div>

                <div className='mb-2 rounded-r-2xl w-[90%] lg:w-[40%]  border border-gray-200 shadow-lg flex flex-row items-center md:justify-between  mt-8 custom-border '>
                    <div className='grow flex items-center '>
                        <img src={userr} alt='username' className='w-14 h-14 mr-2' />
                        <input type='text' onChange={(e) => setUser(e.target.value)} className='grow bg-transparent focus:outline-none' placeholder='Username' />
                    </div>
                </div>




                <div className='mb-2 rounded-r-2xl w-[90%] lg:w-[40%]  border border-gray-200 shadow-lg flex flex-row items-center md:justify-between  mt-3 custom-border'>
                    <div className='flex items-center grow'>
                        <img src={Message} alt='email' className='w-14 h-14 mr-2' />
                        <input type='text' onChange={(e) => setEmail(e.target.value)} className='grow bg-transparent focus:outline-none' placeholder='Email' />
                    </div>
                </div>

                <div className='mb-2 rounded-r-2xl w-[90%] lg:w-[40%]  border border-gray-200 shadow-lg flex flex-row items-center md:justify-between  mt-3 custom-border '>
                    <div className='grow flex items-center'>
                        <img src={Lock} alt='password' className='w-14 h-14 mr-2' />
                        <input type='password' onChange={(e) => setPassword(e.target.value)} className='grow bg-transparent focus:outline-none' placeholder='Password' />
                    </div>
                </div>

                <div className='mb-2 rounded-r-2xl w-[90%] lg:w-[40%]  border border-gray-200 shadow-lg flex flex-row items-center md:justify-between  mt-3 custom-border'>
                    <div className='grow flex items-center'>
                        <img src={Lock} alt='password' className='w-14 h-14 mr-2' />
                        <input type='password' onChange={(e) => setPassword(e.target.value)} className='grow bg-transparent focus:outline-none' placeholder='Confirm Password' />
                    </div>
                </div>



                <button className='py-3 lg:w-[35%] w-[90%] bg-[#102048] text-lg text-white mt-10 mb-8 rounded-full' onClick={RedirectLogin}>Register </button>

                <div className='flex justify-center items-center'>
                    <p className='text-lg'>Already have an account? </p>
                    <button class='text-[#14227D] ml-2  text-lg font-semibold' onClick={(e) => window.location.replace('/login')}>Login</button>
                </div>



            </div>




        </>
    )
}

export default Signup

/*<div class='w-full h-screen bg-gradient-to-br from-[#fcc502] to-[#03296b] '>
            {loading && (<div className="flex items-center justify-center h-screen transition-all ">
                <div className="relative">
                    <div className="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
                    <div className="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin">
                    </div>
                </div>
            </div>)}
            <Navbar check={1} />
            <div class='w-full h-full flex justify-center items-center p-6'>
                <div class='lg:w-[37%] md:w-[50%] sm:w-[85%] items-center flex flex-col space-y-14 bg-white rounded-xl py-10 px-14 mt-20'>
                    <div class='flex flex-col space-y-5 items-center'>
                        <text class='text-4xl font-font1 font-bold text-center'>Crie a sua conta aqui</text>
                        <text class='text-center text-black/50'>Bem-vindo ao Kassoma IA Speech Analyzer! Vamos configurar você!</text>
                    </div>
                    <div class='w-full flex flex-col space-y-5'>
                        <div class='w-full flex flex-col'>
                            <text class='text-black/70'>Email</text>
                            <input onChange={(e) => setUser(e.target.value)} class='w-full pt-3 border-b-[2px] outline-none pb-1' placeholder='abc@mail.com'></input>
                        </div>
                        <div class='w-full flex flex-col'>
                            <text class='text-black/70'>Password</text>
                            <input onChange={(e) => setPassword(e.target.value)} class='w-full pt-3 border-b-[2px] outline-none pb-1' placeholder='........' type='password'></input>
                        </div>
                    </div>

                    <div class='w-full space-y-5 flex flex-col'>
                        <button class='w-full py-3 rounded-xl font-font2 text-lg bg-[#03296b] text-white' onClick={RedirectLogin}>Crie a sua conta aqui</button>
                        <div class='flex items-center space-x-5'>
                            <hr class='w-1/2 border-[1px]' />
                            <text class='text-black/50'>ou</text>
                            <hr class='w-1/2 border-[1px]' />
                        </div>
                        <div class='flex w-full justify-center space-x-2'>
                            <text>Já tem uma conta? </text>
                            <button class='text-[#03296b] underline' onClick={(e) => window.location.replace('/login')}>Entrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div> */