import React from 'react'
import BackgroundSVG from './BackgroundSVG'
import Navbar from './Navbar'
import HeroSection from './HeroSection'
import Section2 from './section2'
import { SE } from 'country-flag-icons/react/3x2'
import Pricing from './Pricing'
import Section3 from './Section3'
import Section4 from './Section4'
import Footter from './Footter'
//import HeroSectionContent from './HeroSectionContent'

function LandingPage() {
  return (
    <div class='w-full flex flex-col'>
      <div id="Home" class='w-full min-h-screen'>
        <Navbar />
        <div className='mt-24 w-full flex flex-row items-center justify-center'>
      <HeroSection />
      </div>
       <Section2 />
       <Section3 />
        
      </div>

      <div />
      
      <Pricing />
     <Section4 />
      <Footter/>


      
      
    </div>
  )
}

export default LandingPage


/* 
<div id="About" />
<div className="mt-16" />
      <HeroSectionContent />
      <Pricing /> */