import React from 'react'
import tick from '../assets/tick.png'

function SinglePrice(props) {

    return (
        <div className='flex flex-col items-center justify-center rounded-2xl shadow-lg border border-gray-200 w-[40vh] h-[46vh] '>
            <div className="flex flex-col items-start justify-center pb-32">
                <div className='bg-[#102048] py-2 px-5 rounded-md '>
                    <p className='text-white font-bold '>Livre</p>
                </div>
                <div className='flex flex-row mt-4 mb-4'>
                    <p className='font-bold text-4xl'>€0 </p>
                    <p className='mt-1 ml-2 font-light text-2xl'>/mo</p>
                </div>
            </div>
            <div className='flex flex-col items-center justify-center '>
                <div className='flex flex-row'>
                    <img src={tick} alt='tick' className=' w-5 h-5 mr-2 ' />
                    <p className='font-md text-semibold'>15 chats por dia</p>

                </div>
                <div className='flex flex-row'>
                    <img src={tick} alt='tick' className='ml-1.5 w-5 h-5 mr-2 ' />
                    <p className='font-md text-semibold'>Revisão 2 Tarefas</p>

                </div>


            </div>

        </div>
    )
}

export default SinglePrice