import React, { useState, useEffect, useRef } from 'react'
import { BsVectorPen, BsBookHalf } from 'react-icons/bs'
import { RiVoiceprintFill } from 'react-icons/ri'
import { BiSolidSend, BiMenu } from 'react-icons/bi'
import UserChat from './UserChat'
import BotChat from './BotChat'
import Sidebar2 from './Sidebar2'
import axios from 'axios'
import { supabase, checkIfLogin } from '../supa'
import Swal from "sweetalert2";
// window.MediaRecorder = AudioRecorder

function Dashboard() {
    const [recordLANG, setRecordLANG] = useState('en-US')
    const [recording, setRecording] = useState(false)
    const [recordingPT, setRecordingPT] = useState(false)
    const [recordingENG, setRecordingENG] = useState(false)

    const [audio, setAudio] = useState(null)
    const [mediaRecorderState, setMediaRecorderState] = useState(null)
    var mediaRecorder;
    let chunks = [];
    const mediaStreamRef = useRef(null);
    const [vChat, setVChat] = useState(false)
    const [Input, setInput] = useState('')
    const [processing, setProcessing] = useState(false)
    const [TotalMessageChat, setTotalMessageChat] = useState([{
        role: 'system', content: 'Give response as Markdown, add two line breaks instead of one.You are a virtual assistant you speak in Portugese, Your name is Kasy, and your creator is Garinaldo Fernades Chico Duarte. You are a personal English language trainer. Your help portugese people learn english. You only reply in portugese, unless users requires you to reply in English.'
    },
    {
        role: "assistant",
        content: "Olá, eu sou a Kassy, a sua professora particular de inglês, como posso lhe ajudar hoje?",
    }
    ])
    const [TotalMessageLesson, setTotalMessageLesson] = useState([{
        role: 'system', content: 'Give response as Markdown.\
        You are a \
    virtual assistant you speak in Portugese, Your name is Kasy, and your creator is Garinaldo Fernades Chico Duarte. You are a personal English language trainer. Your help portugese people learn english.\
    You will keep the conversation going by asking questions that helps in learning english. \
    At each response you will tell the user if their answer was corrent or not, and give a brief description of they could be better.\
    User will tell you about the level of english the know and you will act acordingly. You only reply in portugese, unless users requires you to reply in English.\
    You will ask in Portuguese if the user intends to have all the classes in Portuguese or in English in the first contact.\
    You will ask for the user name and will address him by name. Only ask one question at a time.'
    },
    {
        role: "assistant",
        content: "Olá! Bem-vindo(a) às lições de inglês.\
        Qual é o seu nível de inglês?\
        1. Iniciante\
        2. Intermediário\
        3. Avançado",
    }])
    useEffect(() => {
        StartChat()
    }, [vChat])

    useEffect(() => {
        console.log(TotalMessageChat)
        document
            .getElementById("ScrollToMe")
            .scrollIntoView({ block: "end", behavior: "smooth" });
    }, [Input])


    var blobToBase64 = function (blob, callback) {
        console.log(blob)
        var reader = new FileReader();
        reader.onload = function () {
            var dataUrl = reader.result;
            var base64 = dataUrl.split(',')[1];
            callback(base64);
        };
        reader.readAsDataURL(blob);
    };



    const transcribeAudio = async (blob) => {
        setProcessing(true)
        try {
            var res = await axios.post(
                `https://speech.googleapis.com/v1/speech:recognize?key=${process.env.REACT_APP_GAPI}`,
                {
                    "config": {
                        "encoding": "LINEAR16",
                        // "sampleRateHertz": 16000,
                        // "enableWordTimeOffsets": "true",
                        "languageCode": recordLANG,
                        "enableWordTimeOffsets": false
                    },
                    "audio": {
                        "content": blob
                    }

                },
                {
                    headers: {
                        //'Authorization': `Bearer ${process.env.REACT_APP_GAPI}`,
                        'Accept': 'application/json',
                    },
                }
            );
        }
        catch (e) {
            throw (JSON.stringify(e.response))
            setProcessing(false)
        }

        // throw (JSON.stringify(res.data.results))
        try {
            console.log(res.data.results[0].alternatives[0].transcript)
            setInput(res.data.results[0].alternatives[0].transcript)
            setVChat(!vChat)
        }
        catch {
            console.log('Error transcibing audio')
        }
    };



    const stopRecording = () => {
        if (mediaRecorderState && recording) {
            mediaRecorderState.stop();
            setRecording(false);
            cleanup();
        }
    };


    const cleanup = () => {
        if (mediaStreamRef.current) {
            const tracks = mediaStreamRef.current.getTracks();
            tracks.forEach((track) => track.stop()); // Stop all tracks in the media stream
        }
        mediaStreamRef.current = null;
    };



    const handleRecordAudio = async (lang) => {
        if (lang === 'en-US') {
            setRecordingENG(true)
        }
        if (lang === 'pt-BR') {
            setRecordingPT(true)
        }
        setRecordLANG(lang)
        recordAudio()


    }

    const recordAudio = async () => {
        const options = {
            mimeType: "audio/webm;codecs=opus",
            sampleRate: 16000,
        };

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                mediaRecorder = new MediaRecorder(stream, options)
                mediaStreamRef.current = stream
                // // For safari
                // try {
                //     mediaRecorder = new MediaRecorder(stream, options);
                // }
                // // For all other
                // catch {
                //     mediaRecorder = new MediaRecorder(stream);
                // }
                // throw (mediaRecorder.mimeType)


                mediaRecorder.addEventListener('start', event => {
                    console.log("Recording now")
                });

                mediaRecorder.addEventListener('dataavailable', event => {
                    console.log("Data Available")
                    if (event.data.size > 0) {
                        chunks.push(event.data);
                    }

                });

                mediaRecorder.addEventListener('stop', event => {
                    console.log('Stopped Recording')
                    const blob = new Blob(chunks, { type: 'audio/webm' });
                    chunks = [];
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        setAudio(blob);
                    };
                    //transcribeAudio(blob)
                    blobToBase64(blob, transcribeAudio)
                });

                mediaRecorder.start();
                setMediaRecorderState(mediaRecorder)
                setRecording(true);
            })
            .catch((error) => console.error('Error accessing media devices:', error));

    }




    const [plan, setPlan] = useState('')
    useEffect(() => {
        getPlan()

    }, [])

    const getPlan = async () => {
        const jwt = `${checkIfLogin()}`
        await axios
            .get(`${process.env.REACT_APP_BACKEND}/stripe/getCurrentPlan/`, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            })
            .then(function (response) {
                if (response.data.length <= 0) {
                    setPlan('Free')
                }
                else if (response.data === `${process.env.REACT_APP_PLAN1}`) {
                    setPlan('Standard')
                }
                else if (response.data === `${process.env.REACT_APP_PLAN2}`) {
                    setPlan('Premium')
                }
            })
            .catch(function (error) {
                console.log(`Error : ${error}}`);
            });
    }
    // Olá, eu sou a Kassy, a sua professora particular de inglês, como posso lhe ajudar hoje?



    const [Gen, setGen] = useState(false)
    const [FirstMessage, SetFirstMessage] = useState(true)

    const [isLesson, setIsLesson] = useState(false)

    const [SidebarCheck, setSidebarCheck] = useState(false)
    useEffect(() => {
        document
            .getElementById("ScrollToMe")
            .scrollIntoView({ block: "end", behavior: "smooth" });
    }, [isLesson])
    function RedirectLogin() {
        window.location.replace('/login')
    }
    function RedirectPayment() {
        window.location.replace('/payment')
    }





    function handleKey(e) {
        if (e.code === "Enter") {
            StartChat()
        }
    }


    function HandleInput(e) {
        setInput(e.target.value)
    }

    async function StartChat() {
        setProcessing(false)
        if (Input.length <= 0) {
            return
        }
        // console.log(input)
        var input_to_save = `${Input}`
        var input = `${Input}`
        // var input = `${Input}.Give your response in HTML and use extensive markup.`// and divide into paragraphs. Use addequte line breaks in your response. If using points break line before each new points.`

        if (input.length > 0) {
            if (checkIfLogin()) {
                const jwt = `Bearer ${checkIfLogin()}`
                if (!isLesson) {

                    var gptArray = JSON.parse(JSON.stringify(TotalMessageChat))
                    gptArray.push({
                        role: "user",
                        content: input,
                    })

                    let gptArray2 = TotalMessageChat
                    gptArray2.push({
                        role: "user",
                        content: input_to_save,
                    })

                    setInput('')

                    console.log(gptArray)
                    document
                        .getElementById("ScrollToMe")
                        .scrollIntoView({ block: "end", behavior: "smooth" });

                    const url = `${process.env.REACT_APP_BACKEND}/chat`;
                    setGen(true)
                    try {
                        const stream = await fetch(`${url}`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: jwt,
                            },
                            body: JSON.stringify({ prompt: gptArray }),
                        });


                        const reader = stream.body.getReader();
                        const decoder = new TextDecoder("utf-8");
                        let temp = ""; // we are getting data from gpt here
                        while (true) {
                            const { done, value } = await reader.read();
                            if (done) {
                                break;
                            }

                            // console.log(decoder.decode(value))
                            if (decoder.decode(value) == '𐍈') {
                                temp += '<br><br>';
                                console.log('new line')
                            }
                            else {
                                temp += decoder.decode(value);
                            }
                            setTotalMessageChat([
                                ...TotalMessageChat,
                                {
                                    role: "assistant",
                                    content: temp,
                                }
                            ]);
                            document
                                .getElementById("ScrollToMe")
                                .scrollIntoView({ block: "end", behavior: "smooth" });
                        }
                    } catch (err) {
                        Swal.fire({
                            title: "Limit Exceeded, Please pay to continue !",
                            icon: "error",
                            confirmButtonText: "ok",
                        });
                        setGen(false)
                    }
                    setGen(false)
                }
                // Lessons
                else {

                    var gptArray = JSON.parse(JSON.stringify(TotalMessageLesson))
                    gptArray.push({
                        role: "user",
                        content: input,
                    })

                    let gptArray2 = TotalMessageLesson
                    gptArray2.push({
                        role: "user",
                        content: input_to_save,
                    })











                    console.log(gptArray)
                    document
                        .getElementById("ScrollToMe")
                        .scrollIntoView({ block: "end", behavior: "smooth" });

                    const url = `${process.env.REACT_APP_BACKEND}/chat`;
                    setGen(true)
                    try {
                        if (FirstMessage) {
                            var stream = await fetch(`${url}`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: jwt,
                                },
                                body: JSON.stringify({ prompt: gptArray, lesson: true }),
                            });
                            SetFirstMessage(false)
                        }
                        else {
                            var stream = await fetch(`${url}`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: jwt,
                                },
                                body: JSON.stringify({ prompt: gptArray }),
                            });
                        }


                        const reader = stream.body.getReader();
                        const decoder = new TextDecoder("utf-8");
                        let temp = ""; // we are getting data from gpt here
                        while (true) {
                            const { done, value } = await reader.read();
                            if (done) {
                                break;
                            }
                            temp += decoder.decode(value);
                            setTotalMessageLesson([
                                ...TotalMessageLesson,
                                {
                                    role: "assistant",
                                    content: temp,
                                }
                            ]);
                            document
                                .getElementById("ScrollToMe")
                                .scrollIntoView({ block: "end", behavior: "smooth" });
                        }
                    } catch (err) {
                        Swal.fire({
                            title: "Limit Exceeded, Please pay to continue !",
                            icon: "error",
                            confirmButtonText: "ok",
                        });
                        setGen(false)
                    }
                    setGen(false)
                }
                setGen(false)
            }
            else {
                Swal.fire({
                    title: "Error ! Please login",
                    icon: "error",
                    confirmButtonText: "ok",
                }).then(() => {
                    window.location.replace('/login')
                });
            }
            setInput("")
        }
        else {
            // Swal.fire({
            //     title: "Error ! Please enter a message",
            //     icon: "error",
            //     confirmButtonText: "ok",
            // }).then(() => {
            // });
        }


        // console.log(TotalMessageChat)

    }

    function HandleSideBar() {
        setSidebarCheck(!SidebarCheck)
    }

    return (
        <div class={`w-full md:h-screen h-screen flex `}>

            {
                SidebarCheck == true ?
                    <Sidebar2 logOut={RedirectLogin} payment={RedirectPayment} isLesson={isLesson}
                        setIsLesson={setIsLesson} />
                    :
                    <></>
            }

            <div class={`lg:w-[19%] md:w-[24%] h-full bg-[#03296b]/5 
            backdrop-blur-md md:flex hidden flex-col py-10 lg:px-4 md:px-4 items-start 
            justify-between`}>
                <div className="flex flex-col space-y-5">
                    <text class='text-3xl font-font1 font-bold '>Kassoma IA</text>
                    <text class={`text font-font1 font-bold px-2 shrink ${plan === "Free" ? 'bg-red-500 rounded-xl' : 'bg-green-500 rounded-xl'}`}>{plan ? `${plan} Plan` : ''}</text>

                </div>

                <div class='w-full flex flex-col space-y-1'>

                    <button
                        onClick={() => setIsLesson(false)}
                        class={`flex transition-all items-center font-font2 space-x-5 py-3 px-3 rounded-full hover:bg-[#f1ce4d] ${!isLesson ? "bg-[#fcc502]" : ""}`}>
                        <BsVectorPen size='1.5rem' color='#03296b' />
                        <text class='text-xl'>Aprender</text>
                    </button>

                    <button
                        onClick={() => setIsLesson(true)}
                        class={`flex transition-all items-center font-font2 space-x-5 py-3 px-3 rounded-full hover:bg-[#f1ce4d] ${isLesson ? "bg-[#fcc502]" : ""}`}>
                        <BsBookHalf size='1.5rem' color='#03296b' />
                        <text class='text-xl'>Lição</text>
                    </button>

                </div>

                <div class='flex space-x-2'>
                    <button class='p-3 bg-[#03296b] font-font1 text text-white rounded-lg ' onClick={RedirectLogin}>Log out</button>
                    <button class='p-3 bg-[#03296b]  font-font1 text text-white rounded-lg ' onClick={RedirectPayment}>Cobrança</button>
                </div>
            </div>

            <div class='w-fit md:hidden flex py-3 z-50' onClick={HandleSideBar}>
                <BiMenu size='2.5rem' />
            </div>


            <div class='md:w-[80%] w-full h-full md:p-10 p-4 flex flex-col justify-between items-center space-y-2'>
                <div class={`lg:w-[65%] md:w-[80%] w-full flex flex-col overflow-y-scroll chat 
                 ${recording || processing ? "motion-safe:animate-ping backdrop-blur-3xl motion-safe:animate-pulse pointer-events-none" : ""}`} id="chat">
                    {!isLesson &&
                        TotalMessageChat.map((obj) =>
                            obj.role === 'user' ? (
                                <UserChat content={obj.content} />
                            ) : obj.role === 'assistant' ? (
                                <BotChat content={obj.content} />
                            ) : null
                        )
                    }
                    {isLesson &&
                        TotalMessageLesson.map((obj) =>
                            obj.role === 'user' ? (
                                <UserChat content={obj.content} />
                            ) : obj.role === 'assistant' ? (
                                <BotChat content={obj.content} />
                            ) : null
                        )
                    }

                    <div className="" id="ScrollToMe"></div>

                </div>
                <div class='lg:w-[65%] md:w-[80%] w-full flex flex-col justify-center items-center border-[2px] rounded-lg md:mr-0 lg:mr-1 '>
                    <input onKeyDown={(e) => handleKey(e)} id="input" class='w-full rounded-lg py-3 px-3 outline-none text-base font-font2' placeholder='Ask or search anything' onChange={HandleInput} value={Input}></input>

                    <div onSubmit={(e) => { e.preventDefault(); StartChat() }}
                        class='w-full px-2 py-2 bg-gray-100 flex justify-between'>

                        <div className="flex flex-row">
                            <button
                                onClick={
                                    () => {
                                        if (recording) {
                                            setRecording(false)
                                            stopRecording()
                                            setRecordingENG(false)

                                            return
                                        }
                                        handleRecordAudio("en-US")
                                    }


                                }
                                class={`py-2 px-2 flex items-center md:hover:bg-[#fcc502] rounded-lg ${recordingENG ? 'bg-[#fcc502]' : ''}`}>
                                <RiVoiceprintFill size='1.2rem' />
                                <div

                                    class='sm:text-sm text-xs ml-3 font-font2'>Reconhecimento de voz [ENG]</div>
                            </button>
                            <button
                                onClick={
                                    () => {
                                        if (recordingPT) {
                                            setRecording(false)
                                            setRecordingPT(false)
                                            stopRecording()
                                            return
                                        }
                                        handleRecordAudio("pt-BR")
                                    }


                                }
                                class={`py-2 px-2 flex items-center md:hover:bg-[#fcc502] rounded-lg ${recordingPT ? 'bg-[#fcc502]' : ''}`}>
                                <RiVoiceprintFill size='1.2rem' />
                                <div

                                    class='sm:text-sm text-xs ml-3 font-font2'>Reconhecimento de voz [PT]</div>
                            </button>
                        </div>
                        <button id="ScrollToMe2" disabled={Input.length <= 0 || Gen} type="submit" onClick={StartChat} class={Input.length > 0 && !Gen ? 'flex p-2 justify-center items-center border-[2px] bg-[#fcc502] rounded-full' : 'flex p-2 justify-center items-center border-[2px] bg-[#fcc502]/10 rounded-full'}>
                            <BiSolidSend size='1.2rem' color='white' />
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Dashboard